import { combineReducers } from "redux";
import authMessageReducer from "./authMessageReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import rewardReducer from "./rewardReducer";
import surveyReducer from "./surveyReducer";

export default combineReducers({
  profile: profileReducer,
  auth: authReducer,
  message: authMessageReducer,
  reward: rewardReducer,
  assignedSurveys: surveyReducer
});
