import React, {  } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./pages/Dashboard";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import CaptureResponse from "./pages/CaptureResponse";
import ErrorPage from "./pages/ErrorPage"
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { Profile } from "./pages/Profile";
import { Detail } from "./pages/Details";
import { Points } from "./pages/Points";
import { Refer } from "./pages/Refer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LandingPage from "./landingpage/newPage3/Main";
import PrivacyPolicy from "./landingpage/newPage3/PrivacyPolicy";
import TermServicePage from "./landingpage/newPage3/TermService";
import AboutPage from "./landingpage/newPage3/About";
import ReferralPage from "./landingpage/newPage3/Referral";
import SignOut from "./pages/SignOut"
import Faq from "./landingpage/newPage3/FAQ";

function App() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="App">
        <Switch>
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/sign-in" exact component={SignIn} />
          <Route path="/" exact component={LandingPage} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/term-service" exact component={TermServicePage} />
          <Route path="/about" exact component={AboutPage} />
          <Route path="/Faq" exact component={Faq} />
          <Route path="/referral" exact component={ReferralPage} />
          <Route path="/post-survey" exact component={CaptureResponse}/>
          <Route path="/error" exact component={ErrorPage}/>
          {isLoggedIn  && 
            <Main>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/term-service" exact component={TermServicePage} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/personal-details" component={Detail} />
              <Route exact path="/points" component={Points} />
              <Route exact path="/refer" component={Refer} />
              <Route exact path="/signout" component={SignOut} />
            </Main>
          }
          {!isLoggedIn && <Route path="/*" exact component={LandingPage} />}
        </Switch>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
