import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "../../assets/svg/CloseIcon";
import { notification, Form, Checkbox, } from "antd";

import {
  Col,
  Row,
  Input,
  Divider,
} from "antd";


import { socialLogin } from "../../../../redux/actions/authActions/auth";


import { GoogleLogin } from "@react-oauth/google";

import {
  CustomModal,
  LoginInformationContent,
  GoogleLoginButtonContainer,
  
} from "./style";



import { ContinuumButton, ContinuumHeading } from "./UIElements";
import { continuumEmailRegister } from "../../../../services/identityService";


const Signup = ({ isLoginModalOpen, setIsLoginModalOpen, onGoogleLoginSuccess, }) => {
  const handleOk = () => {
    setIsLoginModalOpen(true);
  };

  const handleCancel = () => {
    setIsLoginModalOpen(false);
  };

  const { message } = useSelector((state) => state.message);

  const onGoogleLoginFailure = () => {
    notification.error({message: "Goolge Login Failed"})
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    continuumEmailRegister(values.name,values.email,values.password);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <CustomModal
        open={isLoginModalOpen}
        width={1000}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
         <div className="login-modal-wrapper">
          <Row gutter={24}>
          <Col sm={{ span: 24 }} md={{ span: 12 }} className="Free-survey-wrapper">
            <LoginInformationContent className="bg">
              <ContinuumHeading
                size={2}
                className="content-padding"
                style={{ color: "#0b093b" }}
              >
                Fill Simple, Free Survey
              </ContinuumHeading>
            </LoginInformationContent>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }} className="content-padding">
            
<div className="form-wrap">
   <ContinuumHeading size={2}>Welcome to Continuum</ContinuumHeading>

    <GoogleLoginButtonContainer className="mb-20 mt-16 textCenter">
      <div className="auto-button-group">
<span className="mx-center">
                <GoogleLogin 
                onSuccess={onGoogleLoginSuccess}
                onError={onGoogleLoginFailure}
                text="continue_with"
                useOneTap
              />
              </span>
      </div>
              
              
            </GoogleLoginButtonContainer>

        
            <Divider>Or</Divider>
            {/* <Input
              placeholder="Enter Email Address"
              prefix={<MailOutlined />}
            /> */}
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
            >
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "The input is not valid E-mail!" },
                ]}
              >
                <Input placeholder="email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Should accept agreement")),
                  },
                ]}
              >
                <Checkbox>
                  I agree the{" "}
                  <a href="#pablo" className="font-bold text-dark">
                    Terms and Conditions
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <ContinuumButton
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  
                >
                  SIGN UP
                </ContinuumButton>
              </Form.Item>
            </Form>
            {/* <ContinuumButton className="mt-16 mb-20 w-100">
              Continue
            </ContinuumButton> */}
</div>
           
          </Col>
        </Row>
        </div>
      </CustomModal>
       
        
      
    </>
  );
};

export default Signup;
