import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../style/flexboxgrid.min.css";
import "../style/index.css";


// Sections


import { getIpDetails } from "../../services/identityService";
import About from "./psgeComponents/About";
import Header from "./psgeComponents/header/Header";
import Footer from "./psgeComponents/footer";





export default function AboutPage() {
  getIpDetails().then((res)=>{})
  const history = useHistory();

  const { isLoggedIn } = useSelector((state) => state.auth);
  if (isLoggedIn) {
    history.push("/dashboard");
  }

  return (
    <>
    <Header/>
    <div style={{marginTop:"90px"}}>
        <About/>  
    </div>

    <Footer/>
     
    </>
  );
}