import {
  Card,
  Col,
  Row,
} from "antd";


import step1 from "../assets/img/how-work1.jpg";
import step2 from "../assets/img/how-work2.jpg";
import step3 from "../assets/img/how-work3.jpg";

const { Meta } = Card;

export default function HowItWork() {
  return (
    <>
<section>
  <div className="container pt-20 mb-30">
      <div className="heading">
            <h2 className="">How It Works?</h2>
          
          </div>
        <Row className="bgblack how-work-wrapper" gutter={24} align="middle">
        <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 2 }}>
        
          </Col>
         <Col className="mt-16"  xs={{ span: 24 }} sm={{ span: 10 }}  >
           <div className="wrapper whiteColor">
           <h2 className="whiteColor">Register</h2>
           <p>To start using Opinion Bull for online surveys, users need to register an account. Registration is a simple process that requires users to provide basic information such as their name, email address, and a password. Once registered, users can access their account dashboard, where they can view available surveys, check their rewards, and manage their profile.</p>
           
           </div>
          </Col>
          <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 2 }}>
        
          </Col>
          <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 8 }}>
            <Card>
              <div className="how-img">
                <img src={step1} alt="" />
              </div>
            
            </Card>
          </Col>
         
        
        </Row>
        <Row className="bgblack how-work-wrapper wrap-md-revers"  gutter={24} align="middle">
        <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 2 }}>
        
          </Col>
          <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 8 }}>
            <Card>
              <div className="how-img">
              <img src={step2} alt="" />
              </div>
            
            </Card>
          </Col>
         
          <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 2 }}>
        
          </Col>
          <Col className="mt-16"  xs={{ span: 24 }} sm={{ span: 10 }}  >
           <div className="wrapper whiteColor">
           <h2 className="whiteColor">Participate in Survey</h2>
           <p>After registering, users can browse through the available surveys and choose the ones they are interested in participating in. Each survey will have specific requirements and instructions, such as demographic information or completion time. Users can complete surveys at their convenience and submit their responses through the Opinion Bull platform.</p>
           
           </div>
          </Col>
         
        
        </Row>
        <Row className="bgblack how-work-wrapper" gutter={24} align="middle">
        <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 2 }}>
        
          </Col>
         <Col className="mt-16"  xs={{ span: 24 }} sm={{ span: 10 }}  >
           <div className="wrapper whiteColor">
           <h2 className="whiteColor"> Get Rewarded</h2>
           <p>As users participate in surveys, they earn rewards based on their activity. These rewards can vary and may include points, gift cards, or other incentives. Users can track their rewards in their account dashboard and redeem them based on the options available. Opinion Bull ensures a seamless process for rewarding users, motivating them to participate in more surveys and providing valuable insights to survey creators.</p>
           
           </div>
          </Col>
          <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 2 }}>
        
          </Col>
          <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 8 }}>
            <Card>
              <div className="how-img">
              <img src={step3} alt="" />
              </div>
            
            </Card>
          </Col>
         
        
        </Row>
       
      </div>
</section>

{// <Row gutter={24}>
// <Col className="mt-16" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
//   <Card hoverable>
//     <div className="how-img">
//       <video className="w-100" muted loop="true" autoPlay="true">
//         <source src={step1} type="video/mp4" />
//       </video>
//     </div>
//     <h2 class="text-center">Register with us</h2>
//   </Card>
// </Col>
// <Col className="mt-16"  xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
//   <Card hoverable>
//     <div className="how-img">
//       <video className="w-100" muted loop="true" autoPlay="true">
//         <source src={step2} type="video/mp4" />
//       </video>
//     </div>
//     <h2 class="text-center">Participate in Survey</h2>
//   </Card>
// </Col>
// <Col className="mt-16"  xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
//   <Card hoverable>
//     <div className="how-img">
//       <video className="w-100" muted loop="true" autoPlay="true">
//         <source src={step3} type="video/mp4" />
//       </video>
//     </div>
//     <h2 class="text-center">Get Rewarded</h2>
//   </Card>
// </Col>
// </Row>
    }
    </>
  );
}
