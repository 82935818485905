import styled from "styled-components";
import { Button } from "antd";

export const ContinuumButton = styled(Button)`
  background-color: ${(props) => {
    switch (props.color) {
      case "primary":
        return "#40BA7C";
      case "secondary":
        return "transparent";
      case "grey":
        return "#808080";
      default:
        return "#40BA7C";
    }
  }} !important;

  border-color: ${(props) => {
    switch (props.color) {
      case "primary":
        return "#40BA7C";
      case "secondary":
        return "#808080";

      default:
        return "#40BA7C";
    }
  }} !important;
  color: ${(props) => {
    switch (props.color) {
      case "secondary":
        return "#808080";
      default:
        return "#fff";
    }
  }} !important;
  font-weight: ${(props) => {
    switch (props.weight) {
      case "bold":
        return "bold";
      case "normal":
        return "normal";
      default:
        return "normal";
    }
  }} !important;

  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.12px;
  text-transform: uppercase;

  &:disabled {
    color: #00000040 !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
`;

export const ContinuumHeading = styled.p`
  font-weight: 400;
  font-size: ${(props) => {
    switch (props.size) {
      case 1:
        return "42px";
      case 2:
        return "32px";
      case 3:
        return "28px";
      case 4:
        return "20px";
      case 5:
        return "16px";
      case 6:
        return "14px";
      default:
        return "36px";
    }
  }};
  line-height: ${(props) => {
    switch (props.size) {
      case 1:
        return "42px";
      case 2:
        return "32px";
      case 3:
        return "28px";
      case 4:
        return "20px";
      default:
        return "36px";
    }
  }};
  letter-spacing: ${(props) => {
    switch (props.size) {
      case 1:
        return "-0.03em";
      case 4:
        return "-0.01em";
      default:
        return "-0.03em";
    }
  }};
  color: #44475b;
  margin: 0;
  text-align:center;
`;
