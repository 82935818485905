import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { NavLink, useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

// Assets


export default function Footer() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    if (
      document.body.scrollTop > 700 ||
      document.documentElement.scrollTop > 700
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible, false);
    return () => {
      window.removeEventListener("scroll", toggleVisible, false);
    };
  }, []);

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className=" footer pt-20">
      <div className="container">
        <Row gutter={24}>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <div className="footer-logo">
              <Link
                className=" animate pointer"
                to="home"
                smooth={true}
                offset={-80}
              >
                <img
                  src="/logo.png"
                  alt="footer-logo"
                  style={{ width: "240px" }}
                />
              </Link>
            </div>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 8 }} className="text-center">
            <div className="footer-item">
              <h3 className="whiteColor font18">Quick links</h3>
              <li className="semiBold font15 ">
                <NavLink to="/privacy-policy">
                  <span className="whiteColor font13">Privacy Policy</span>
                </NavLink>
              </li>
            </div>
            <div className="footer-item">
              <li className="semiBold font15 ">
                <NavLink to="/term-service">
                  <span className="whiteColor font13">Term Service</span>
                </NavLink>
              </li>
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }} className="text-center">
            <p className="copyright text-center">
              {" "}
              © {getCurrentYear()} -{" "}
              <span className="whiteColor font13">Opinion Bull</span> All
              Right Reserved
            </p>
          </Col>
        </Row>
      </div>
    
    </div>
  );
}
