import { Card, Col, Collapse, Row } from 'antd';
import faqBanner from "../assets/img/faq-banner.jpg"
import React from 'react';
const { Panel } = Collapse;

const FAQ = () => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <>
    <section>
    <div className="about-home">
        <div className="img">
            <img src={faqBanner} alt=""/>
        </div>
        <div className="wrapper">
            <div className="text text-center ">
                <h2 classNameName="whiteColor">
                    FAQ
                </h2>
                <p className="whiteColor">Your questions
                </p>

            </div>
        </div>
    </div>

</section>
        <div id='faq' className="container faq">
          <div className="heading">
            <h2 className=""><span>FAQ's</span> </h2>
            <p>
              Opinion Bull is a great and simple way to earn rewards. Below you’ll find answers to the most frequently asked questions. We periodically update this section. If you have a question and cannot find the answer among those listed below, you can fill our contact form on the contact page.
            </p>
          </div>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} >
              <Card hoverable style={{ borderRadius: "16px", backgroundColor: '#9dc1f21f' }} >
                {/* <div className="faq-card"> */}
                <div className="semiBold font18 mb-20">MEMBERSHIP</div>

                <Collapse bordered={false} defaultActiveKey={['1']} onChange={onChange} style={{ backgroundColor: "#9dc1f21f" }}>
                  <Panel className='font16' header="HOW DO I JOIN?" key="1" >
                    <p>You can sign up for Opinion Bull using the sign up button on top of this page. You’ll have to create a unique user name and email id. However, we don’t allow several different email addresses in the panel.</p>
                  </Panel>
                  <Panel className='font16' header="HOW DO I RECOVER MY PASSWORD?" key="2">
                    <p>Once you sign up, you can login via the Login button on the top of this webpage. In case you forget your password, click on the Forgot Password option and fill out your email id to receive a mail with your password.</p>
                  </Panel>
                  <Panel className='font16' header="HOW CAN I JOIN MY PERSONAL INFORMATION?" key="3">
                    <p>Personal Information is key and we value you sharing that with us. If a need arises for the same to be changed/updated, you can do the same by logging in to your page that can be reached via the login button. Our proprietary toll will be customising the surveys sent to you based on the details provided in the section which is connected to our rewards mechanism. If you want to change your registered email address, you can do the same by filling the contact form on the contact page and our team will help you with it.</p>
                  </Panel>
                  <Panel className='font16' header="HOW DO I CLOSE MY Opinion Bull ACCOUNT" key="4">
                    <p>Personal Information is key and we value you sharing that with us. If a need arises for the same to be changed/updated, you can do the same by logging in to your page that can be reached via the login button. Our proprietary toll will be customising the surveys sent to you based on the details provided in the section which is connected to our rewards mechanism. If you want to change your registered email address, you can do the same by filling the contact form on the contact page and our team will help you with it.</p>
                  </Panel>
                </Collapse>
                {/* </div> */}
              </Card>
              <Card hoverable style={{ borderRadius: "16px", backgroundColor: '#9dc1f21f' }}>
                <div className="semiBold font18 mb-20">REWARDS</div>

                <Collapse bordered={false} onChange={onChange} style={{ backgroundColor: "#9dc1f21f" }}>
                  <Panel className='font16' header="HOW DO I REQUEST FOR REWARDS?" key="1" >
                    <p>Log in to your dashboard via the login button. Click on “My Rewards”, choose the rewards and follow the steps shown on your screen.</p>
                  </Panel>
                  <Panel className='font16' header="MY REWARDS ARE NOT SHOWING - HELP?" key="2">
                    <p>Once you request rewards, our back-end team verifies the requests, cross-checks with the internal team, and then sets up the rewards. This process takes 7-10 Business Days. However, you can always see the status of your requests on your dashboard.</p>
                  </Panel>
                  <Panel className='font16' header="HOW DO I USE MY REWARDS?" key="3">
                    <p>You can use your rewards as per your choice. We’ve partnered with over 100+ Brands in India across fashion, accessories, restaurants, lifestyle, and experiences to give you gift cards. You can read more in the “Incentives” section of our website.</p>
                  </Panel>
                </Collapse>
              </Card>
              <Card hoverable style={{ borderRadius: "16px", boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", backgroundColor: '#9dc1f21f' }}>
                <div className="semiBold font18 mb-20">SURVEYS</div>

                <Collapse bordered={false} onChange={onChange} style={{ backgroundColor: "#9dc1f21f" }}>
                  <Panel className='font16' header="AT TIMES, I ONLY GET TO ANSWER A FEW QUESTIONS,WHY DOES THAT HAPPEN?" key="1" >
                    <p>Every Survey is designed differently. They have different objectives and are looking for different information. A few surveys are looking for the answers for a certain target group and your responses will determine if you fall in that target group or not, in case you don’t you would be opted out of it. We believe in optimization, if your answers don’t match the requirement, then you won’t need to participate in an entire survey.</p>
                  </Panel>
                  <Panel className='font16' header="I AM WORRIED ABOUT HOW MY ANSWERS AND PERSONAL DATA IS HANDLED?" key="2">
                    <p>Confidentiality is our core value. Our tools are designed to keep your answers and data safe. Your inputs are captured completely anonymously and the results of the survey are only used in a compiled form. We make it a point that no personal details are shared with any client or external parties.</p>
                  </Panel>
                  <Panel className='font16' header="WHAT IS THE REWARD FOR ME TO FINISHING A SURVEY?" key="3">
                    <p>The rewards are our way of appreciating the time and effort you’ve put in. They vary from survey to survey and their lengths. However, you’d have to fully complete the survey for you to be eligible for a reward.</p>
                  </Panel>
                  <Panel className='font16' header="WHAT IS THE FREQUENCY AT WHICH I WILL RECEIVE THE SURVEYS?" key="4">
                    <p>We wish we could tell you’ll get them all but Rewards are our way. Every Survey is designed differently. They have different objectives and are looking for different information. The number of surveys you receive is based on what information you have provided to us in your personal settings section and how many surveys are currently in progress. However, you can choose the maximum number of surveys that you would like to receive per month through your dashboard.</p>
                  </Panel>
                  <Panel className='font16' header="HOW CAN ONE PARTICIPATE IN SURVEYS?" key="5">
                    <p>We’’ll be sharing the surveys through the email address you’ve provided us. Our emails will carry a link to the survey. Once you click the link, it will take you to a secure page where you can start the survey, and finish it in the stipulated time to receive your reward.</p>
                  </Panel>
                  <Panel className='font16' header="WHENEVER I CLICK ON THE LINK, IT SAYS THE SURVEY HAS ENDED.WHAT DOES THAT MEAN?" key="6">
                    <p>Every survey is time bound and needs to be completed during a certain window of time. At times, if someone clicks on the link in the email at a later date, the time for the survey might be over and the requirement might be closed. Hence, such a message comes up.</p>
                  </Panel>
                </Collapse>
                {/* </div> */}
              </Card>


            </Col>
          


          </Row>
        </div>
    

    </>

  );
};
export default FAQ;