export const TRANSACTION_DATA_REQUESTED = "TRANSACTION_DATA_REQUESTED";
export const TRANSACTION_DATA_SUCCESS = "TRANSACTION_DATA_SUCCESS";
export const TRANSACTION_DATA_ERROR = "TRANSACTION_DATA_ERROR";

export const REFER_CODE_SUBMIT_INITIATED = "REFER_CODE_SUBMIT_INITIATED";
export const REFER_CODE_SUBMIT_SUCCESS = "REFER_CODE_SUBMIT_SUCCESS";
export const REFER_CODE_SUBMIT_ERROR = "REFER_CODE_SUBMIT_ERROR";

export const INVITE_FRIEND_INITIATED = "INVITE_FRIEND_INITIATED";
export const INVITE_FRIEND_SUCCESS = "INVITE_FRIEND_SUCCESS";
export const INVITE_FRIEND_ERROR = "INVITE_FRIEND_ERROR";

export const LIST_REFFERALS_REQUESTED = "LIST_REFFERALS_REQUESTED";
export const LIST_REFFERALS_SUCCESS = "LIST_REFFERALS_SUCCESS";
export const LIST_REFFERALS_ERROR = "LIST_REFFERALS_ERROR";

export const LIST_REFFERALS_TRANSACTION_REQUESTED = "LIST_REFFERALS_TRANSACTION_REQUESTED";
export const LIST_REFFERALS_TRANSACTION_SUCCESS = "LIST_REFFERALS_TRANSACTION_SUCCESS";
export const LIST_REFFERALS_TRANSACTION_ERROR = "LIST_REFFERALS_TRANSACTION_ERROR";

export const WITHDRAW_REQUEST_INITIATED = "WITHDRAW_REQUEST_INITIATED";
export const WITHDRAW_REQUEST_SUCCESS = "WITHDRAW_REQUEST_SUCCESS";
export const WITHDRAW_REQUEST_ERROR = "WITHDRAW_REQUEST_ERROR";