import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Modal, DatePicker, Button, Form, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { me_update } from '../../redux/actions/authActions/auth';


const { Option } = Select;


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
function PersonalProfileModal() {
  const [isprofileModalOpen, setIsprofileModalOpen] = useState(false);
  const { user } = useSelector(({ auth }) => auth);
  useEffect(() => {
    if (user.gender === null || user.gender === undefined || user.gender === "") {
      setIsprofileModalOpen(true)
    }
  }, [user])

  const dispatch = useDispatch();
  const handleCancel = () => {
    setIsprofileModalOpen(false);
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    values.dob = values.dob.format("YYYY-MM-DD")
    console.log(values);
    dispatch(me_update(values));
    setIsprofileModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Complete Your Profile"
        open={isprofileModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button form="extraProfileInfo" key="submit" htmlType="submit" type="primary">
            <SaveOutlined />
            Save
          </Button>
        ]}
      >
        <Form {...layout} form={form} name="extraProfileInfo" onFinish={onFinish} >
          <Form.Item
            name="gender"
            label="Gender"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              getPopupContainer={trigger => trigger.parentElement}
              placeholder="Select Gender"
              allowClear
            >
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHER">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker placeholder="Select Date of birth" style={{ width: '100%' }}></DatePicker>
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
}
export default PersonalProfileModal;