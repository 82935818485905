
import {
  Row,
  Col,Card} from "antd";


import ListReferralsTable from "./MyReferralTable";
import SendInvites from "./SendInvites";
import ApplyReferral from "./ApplyReferral";

function ReferTab() {

  return (
    <>
      <Row gutter={24} style={{ padding: "0 12px" }}>

        <Col  md={12} sm={24} xs={24} style={{ marginTop: "10px" }} >
          <SendInvites />
          
        </Col>
        <Col md={12} sm={24} xs={24} style={{ marginTop: "10px" }}>
          <ApplyReferral />
        </Col>
      </Row>
      
      <Card   style={{ marginTop: "20px"}}  >
      <h2 style={{fontWeight: 'bold', textAlign: "center"}}>Earn more by inviting friends</h2>
        <Row>
         <Col>
         <h3 className="font-semibold m-0" > 1)  Share your referral code</h3>
         <p> Invite your friends by sharing your referral code</p>
         </Col>
        </Row>
        <Row>
        <Col>
        <h4></h4>
        <h3 className="font-semibold m-0"> 2)  Get 10% of their earning</h3>
         <p>Get 10% of the money your friends make by completing surveys. There is no upper limit</p>
         </Col>          
        </Row>
        </Card>
      
        <ListReferralsTable />
      {/* <Row>
        <Col span={24}>
        
        </Col>
      </Row> */}
    </>
  )
}
export default ReferTab;