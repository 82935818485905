
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { socialLogin } from "../../../redux/actions/authActions/auth";
import { saveRefferalCode } from "../../../redux/actions/rewardActions"
import LoginModal from "./header/LoginModal";
import { Button, Form, Input, Radio } from 'antd';
// Assets

export default function LoginPart() {
  const [form] = Form.useForm();
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const search = useLocation().search;
  const refcode = new URLSearchParams(search).get('refcode');

  
  const onGoogleLoginSuccess = (res) => {
    setIsLoginInProgress(true)
    console.log("success:", res);
    dispatch(socialLogin("google", res))
    .then(() => {
      setIsLoginInProgress(false)
        // Apply referral code if user came by invite link
      console.log("Refer Code", refcode)
      if (refcode != null) {
        dispatch(saveRefferalCode({code:refcode.toUpperCase()}));
      }

      history.push("/dashboard");
    })
    .catch(() => {
      setIsLoginInProgress(false)
      notification.error({message: "Login/Registration Failed"})
      history.push("/");
    });
  };
  const onGoogleLoginFailure = () => {
    notification.error({message: "Goolge Login Failed"})
  };


  useGoogleOneTapLogin({
    onSuccess: onGoogleLoginSuccess,
    onError: onGoogleLoginFailure
  });

  return (
    <>
<div>
  <div className="text">
        <h1>Join the Survey and Earn Money</h1>
        <p className="whiteColor">
          Your inputs help brands and organizations to serve the community
          better
        </p>
      </div>
    
</div>

<div className="form">

  <LoginModal/>
</div>

    </>
  );
}

