import {  Col, Row } from 'antd';
import React from 'react';
import Rozerpay from "../assets/img/Razorpay.png"

const RazerpayPage = () => {
  return (
    <>
     <section className='roz-pay-wrapper'>
        
            <div className="container">
                  <Row gutter={24} className="flexCenter">
            <Col sm={{ span: 24 }} md={{ span: 12 }} >
                <div className="roz_img">
                    <img src={Rozerpay} alt="" />
                </div>

            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }} >
                <div className="about-rozerpay">
                    
<p>Get paid for your valuable opinions with our survey website! We offer fast and secure payouts through Paypal.</p>
                </div>


                </Col>
          


          </Row> 
            </div>
       
  
     </section>
        
    

    </>

  );
};
export default RazerpayPage;