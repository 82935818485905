import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/flexboxgrid.min.css";
import "../style/index.css";

// Sections

import { getIpDetails } from "../../services/identityService";
import TopNavbar from "./psgeComponents/header/Header";
import Privacy from "./psgeComponents/Privacy";
import Footer from "./psgeComponents/footer";

export default function PrivacyPolicy() {
  getIpDetails().then((res) => {});
  const history = useHistory();

  const { isLoggedIn } = useSelector((state) => state.auth);
  if (isLoggedIn) {
    history.push("/dashboard");
  }

  return (
    <>
      <TopNavbar />
      <div
        className="policy-container"
        style={{ marginTop: "110px", marginLeft: "50px", marginRight: "50px" }}
      >
        <Privacy />
      </div>
      <Footer />
    </>
  );
}
