import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, BankOutlined, SwapOutlined, FieldTimeOutlined, NumberOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import { pointTransactions } from '../../redux/actions/rewardActions';
import { me } from '../../redux/actions/authActions/auth';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';




const Statistics = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pointTransactions({ "filters": {}, "page": 1, "order_by":["-created_at"], }));
  }, [dispatch]);
  const { transactionData = {}, fetching } = useSelector(({ reward }) => reward);
  console.log("transactions-staticsts:", transactionData);
  useEffect(() => {
    dispatch(me());
  }, [dispatch]);
  const { user } = useSelector(({ auth }) => auth);

  let lastTransactionData = {"amount": 0, "currency": "USD", "created_at": "-"}
  if ("items" in transactionData && transactionData.items.length > 0) {
    lastTransactionData = transactionData.items[0]
  }
  let balance = "0";
  if (user.balance != null || user.balance != undefined) {
    balance = user.balance.INR ?? '0'
  }
  return (

    <div className="site-statistic-demo-card">
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} xl={6} lg={8} className="mb-24">
          <Card bordered={false} >
            <div className="icon-box">{<BankOutlined />}</div>
            <span>  Balance </span>
            <Statistic className='card-data'
              value={balance}
              precision={2}
              suffix={user.currency}
              valueStyle={{
                color: 'black',
                fontSize: '25px',
                fontWeight: 'bolder'
              }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} xl={6} lg={8} className="mb-24">
          <Card bordered={false} >
            <div className="icon-box">{<SwapOutlined />}</div>
            <span>  Last Transaction </span>
            <Statistic
              value={lastTransactionData.amount}
              precision={2}
              valueStyle={{
                color: 'black',
                fontSize: '25px',
                fontWeight: 'bolder'
              }}
              suffix={lastTransactionData.currency}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} xl={6} lg={8} className="mb-24">
          <Card bordered={false} >
            <div className="icon-box">{<FieldTimeOutlined />}</div>
            <span>  Date/Time of Last Transaction </span>
            <Statistic
              value={lastTransactionData.created_at}
              precision={2}
              valueStyle={{
                color: 'black',
                fontSize: '25px',
                fontWeight: 'bolder'
              }}

            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} xl={6} lg={8} className="mb-24">
          <Card bordered={false} >
            <div className="icon-box">{<NumberOutlined />}</div>
            <span>  My Referral Code </span>
            <Statistic
              value={user.referral_code}
              precision={2}
              valueStyle={{
                color: 'black',
                fontSize: '25px',
                fontWeight: 'bolder'
              }}

            />
          </Card>
        </Col>

      </Row>
    </div>
  )
};
export default Statistics;