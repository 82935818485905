import React from "react";
import vid from "../assets/img/video.mp4";
import banner from "../assets/img/banner.mp4";

import HowItWork from "./HowItWork";
import FAQ from "./FAQ";
import Footer from "./footer";
import LoginPart from "./LoginPart";
import RazerpayPage from "./Pay";

// Assets

export default function Home() {
  return (
    <>
      <section>
     
      </section>
      <div className="home-banner position-relative">
        <div className="video overflow-hidden">
        <video classNameName="w-100" muted loop="true" autoPlay="true">
        <source src={banner} type="video/mp4" />
      </video>
        </div>
        <div className="banner-content">
          <div className="container h-100">
            <div className="row d-flex h-100 justify-content-center align-items-center">
              <div className="col-lg-9">
                <div className="wrapper text-white text-center">
                <LoginPart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    
        <RazerpayPage/>
     
      <div classNameName="how mb-30">
        <HowItWork />
      </div>
  
      <Footer />
    </>
  );
}
