import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../style/flexboxgrid.min.css";
import "../style/index.css";

// Sections

import { getIpDetails } from "../../services/identityService";
import TopNavbar from "./psgeComponents/header/Header";
import Footer from "./psgeComponents/footer";
import Referral from "./psgeComponents/Referrel";

export default function ReferralPage() {
  getIpDetails().then((res) => {});
  const history = useHistory();

  const { isLoggedIn } = useSelector((state) => state.auth);
  if (isLoggedIn) {
    history.push("/profile");
  }

  return (
    <>
      <TopNavbar />
      <div
        className="policy-container"
        style={{ marginTop: "90px" }}
      >
        <Referral />
      </div>
      <Footer />
    </>
  );
}
