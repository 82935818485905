import React from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "../style/flexboxgrid.min.css";
import "./main.css";
import "./style.css";
import { getIpDetails } from "../../services/identityService";

import Home from "./psgeComponents/Home";
import Header from "./psgeComponents/header/Header";



export default function LandingPage() {

  getIpDetails().then((res)=>{})
  const history = useHistory();

  const { isLoggedIn } = useSelector((state) => state.auth);
  if (isLoggedIn) {
    history.push("/dashboard");
  }

  // App Referral Handling
  const search = useLocation().search;
  const refcode = new URLSearchParams(search).get('refcode');
  if (refcode!=null || refcode!=undefined) {
    var userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.indexOf("android") > -1) {
        window.location.replace("https://play.google.com/store/apps/details?id=com.websamp.app&referrer=app_refcode=" + refcode + "&utm_medium=app");
    }
  }
  const token = new URLSearchParams(search).get('token');
  if (token!=null || token!=undefined) {

    axios.get(process.env.REACT_APP_BASE_API_URL + "/v1/api/identity/me?token=" + token).then((response) => {
      if (response.data.data) {
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("user", JSON.stringify(response.data.data));
        window.location.reload();
        // history.push("/profile?token=" + token);
      }
    });

  }




  // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  // (function(){
  // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  // s1.async=true;
  // s1.src='https://embed.tawk.to/63d00aedc2f1ac1e202f6091/1gnialfp1';
  // s1.charset='UTF-8';
  // s1.setAttribute('crossorigin','*');
  // s0.parentNode.insertBefore(s1,s0);
  // })();


  return (
    <>
    <Header/>
     <Home/>
    </>
  );
}
