import ProfileModal from "../../components/profileUpdate";

import ReferTab from "../../components/ReferTab";
import { UserCoverPage} from "../../components/profilesurvey/userCoverPage";

export const Refer = () => {
  return (
    <>
      <UserCoverPage/>
      <ReferTab/>
      <ProfileModal/>
    </>
  );
}

