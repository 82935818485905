import { Col, Row, Typography, Progress } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileSurveyCategory } from "../../redux/actions/profileActions";
import ProfileSurveyCategory from "./Category";
import { ProfileSurveyContainer } from "./style";
const { Title, Paragraph } = Typography;

const ProfileSurvey = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(profileSurveyCategory());
  }, [dispatch]);
  const { allCategoryData = {} } = useSelector(({ profile }) => profile);

  let total = 0
  let responded = 0
  for (let i =0; i < Object.values(allCategoryData).length; i++) {
    let categoryData = Object.values(allCategoryData)[i]
    responded = responded + categoryData.response_count;
    total =  total + categoryData.questions_count;
  }

  const completePercent = parseInt((responded / total) * 100)
  return (
    <ProfileSurveyContainer>
      <Title level={2}>Profile Surveys</Title>
      <Progress
            percent={completePercent}
            style={{width:"90%"}}
            format={(percent) => <Title level={5}>{percent}% Completed</Title>}
      />
      <Paragraph>
        Completing and ensuring your profile surveys remain up to date will
        increase your chances of being matched to highly-rewarding projects,
        tailored just for you.
      </Paragraph>
      <Row gutter={[16, 24]}>
        {Object.values(allCategoryData).map((categoryData) => {
          return (
            <Col xl={6} lg={6} md={12} sm={24} xs={24} key={categoryData.id}>
              <ProfileSurveyCategory categoryData={categoryData} />
            </Col>
          );
        })}
      </Row>
    </ProfileSurveyContainer>
  );
};

export default ProfileSurvey;
