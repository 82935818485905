import {
  TRANSACTION_DATA_REQUESTED,
  TRANSACTION_DATA_SUCCESS,
  TRANSACTION_DATA_ERROR,
  REFER_CODE_SUBMIT_INITIATED,
  REFER_CODE_SUBMIT_SUCCESS,
  REFER_CODE_SUBMIT_ERROR,
  INVITE_FRIEND_INITIATED,
  INVITE_FRIEND_ERROR,
  INVITE_FRIEND_SUCCESS,
  LIST_REFFERALS_REQUESTED,
  LIST_REFFERALS_SUCCESS,
  LIST_REFFERALS_ERROR,
  LIST_REFFERALS_TRANSACTION_REQUESTED,
  LIST_REFFERALS_TRANSACTION_SUCCESS,
  LIST_REFFERALS_TRANSACTION_ERROR,
  WITHDRAW_REQUEST_INITIATED,
  WITHDRAW_REQUEST_ERROR,
  WITHDRAW_REQUEST_SUCCESS,
  
} from "./actionTypes";
import { notification } from "antd";

import rewardService from "../../../services/rewardService";
import { continuumMe } from "../../../services/identityService"

 export const pointTransactions = (payload) => async (dispatch, getState) => {
  dispatch({
    type: TRANSACTION_DATA_REQUESTED,
  });

  try {
    const response = await rewardService.getAllRewardData(payload);
    if (response.status != 200) {
      dispatch({
        type: TRANSACTION_DATA_ERROR,
        payload: response.message,
      });
    } else {
      dispatch({
        type: TRANSACTION_DATA_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: TRANSACTION_DATA_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};

export const saveRefferalCode = (referCode) => async (dispatch, getState) => {
  dispatch({
    type: REFER_CODE_SUBMIT_INITIATED
  });

  try {
    const response = await rewardService.saveRefferalCode(referCode);
    
    if (response.status != 200) {
      dispatch({
        type: REFER_CODE_SUBMIT_ERROR,
        payload: response.data.message,
      });
    } else {
      dispatch({
        type: REFER_CODE_SUBMIT_SUCCESS,
        payload: referCode,
      });
      notification.success({
        message: response?.data?.message,
      });
    }
  } catch (error) {
    console.log(error)
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: REFER_CODE_SUBMIT_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};

export const withdrawRequestUser = (withdrawDetails) => async (dispatch, getState) => {
  dispatch({
    type: WITHDRAW_REQUEST_INITIATED
  });

  try {
    const response = await rewardService.withdrawRequest(withdrawDetails);
    
    if (response?.error) {
      notification.error({
        message: response.message,
      });
    } else {
      dispatch({
        type: WITHDRAW_REQUEST_SUCCESS,
        payload: withdrawDetails,
      });
      notification.success({
        message: "Withdraw Request Sent Successfully",
      });
      await continuumMe()
      // Do
    }
  } catch (error) {
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: WITHDRAW_REQUEST_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};


export const inviteUserFriend=
(emails) => async (dispatch, getState) => {
  dispatch({
    type: INVITE_FRIEND_INITIATED
  });

  try {
    const response = await rewardService.inviteFriends(emails)
   
    if (response?.data?.error) {
      dispatch({
        type: INVITE_FRIEND_ERROR,
        payload: response.data.message,
      });
    } else {
      dispatch({
        type: INVITE_FRIEND_SUCCESS,
        payload: emails,
      });
      notification.success({
        message: "Sent invitation to " + response.data.emails + " users",
      });
    }
  } catch (error) {
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: INVITE_FRIEND_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};

export const myRefferalsList = () => async (dispatch, getState) => {
  dispatch({
    type: LIST_REFFERALS_REQUESTED ,
  });

  try {
    const response = await rewardService.getMyRefferals();
    if (response.status != 200) {
      dispatch({
        type: LIST_REFFERALS_ERROR,
        payload: response.message,
      });
    } else {
      dispatch({
        type: LIST_REFFERALS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: LIST_REFFERALS_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};


export const listReferrals = (body) => async (dispatch, getState) => {
  dispatch({
    type: LIST_REFFERALS_REQUESTED ,
  });

  try {
    const response = await rewardService.listRefferals(body);
    console.log("listReferrals:", response)
    if (response.status != 200) {
      dispatch({
        type: LIST_REFFERALS_ERROR,
        payload: response.message,
      });
    } else {
      dispatch({
        type: LIST_REFFERALS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: LIST_REFFERALS_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};

export const listReferralTransactions = (body) => async (dispatch, getState) => {
  dispatch({
    type: LIST_REFFERALS_TRANSACTION_REQUESTED ,
  });
  const state = getState()
  try {
    console.log("before rewardActions.listReferralTransactions:")
    const response = await rewardService.listRefferalTransaction(body);
    state.listReferralsTransactionData[body.referee_id] = response.data
    console.log("rewardActions.listReferralTransactions:", response)
    if (response.status != 200) {
      dispatch({
        type: LIST_REFFERALS_TRANSACTION_ERROR,
        payload: response.message,
      });
    } else {
      dispatch({
        type: LIST_REFFERALS_TRANSACTION_SUCCESS,
        payload: state.listReferralsTransactionData,
      });
    }
  } catch (error) {
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: LIST_REFFERALS_TRANSACTION_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};