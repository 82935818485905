import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Image,
  Card,
  Space,
  Tag
} from "antd";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const { Title, Paragraph } = Typography;
const { Footer, Content } = Layout;

const ErrorPage = () => {
  const search = useLocation().search;
  let status = new URLSearchParams(search).get('status');
  let message = new URLSearchParams(search).get('message');
  const history = useHistory();
  console.log("query params", search)
  let statusMap = {
    "200": { "text": "Success", "color":"green", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Completing_re_i7ap.png" },
    "400": { "text": "Bad Request", "color":"#1677ff", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    "401": { "text": "Unauthorized", "color":"#1677ff", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    "404": { "text": "Not Found", "color":"red", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    "500": { "text": "Server Error Opinion Bull", "color":"red", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    "null": { "text": "Error", "color":"red", "img": "https://ik.imagekit.io/websamp/websamp/minion.jpg" },
    
  }

  console.log(message)

  if (message == null) {
    message = ""
  }

  if (!(status in statusMap)) {
    status = null
  }

  let errorObj = statusMap[status];
 
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Row>
                <Col style={{marginTop:"200px", marginLeft:"20px"}}>
                  <Title level={1} style={{color: errorObj.color}} >{errorObj.text}</Title>
                  <Title level={3}>{message}</Title>
                </Col>
              </Row>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              {/* <img src={signinbg} alt="" /> */}
              <img src={errorObj.img} alt="" />
            </Col>
          </Row>

        </Content>

      </Layout>
      <Footer style={{bottom:0, position: "fixed", width:"100%", textAlign:"center"}}>
          <p className="copyright">
            {" "}
            Copyright © 2022  <a href="#pablo">Continumm insights</a>.{" "}
          </p>
        </Footer>
    </>
  );
}
export default ErrorPage;