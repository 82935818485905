import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Image,
  Card,
  Space,
  Tag
} from "antd";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import signinbg from "../assets/images/img-signin.jpg";

const { Title, Paragraph } = Typography;
const { Footer, Content } = Layout;

const CaptureResponse = () => {
  const [secound, setSecound] = useState(5)
  const search = useLocation().search;
  const surveyStatus = new URLSearchParams(search).get('status');
  const history = useHistory();
  console.log("query params", search)
  let statusMap = {
    "completed": { "text": "Success", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Completing_re_i7ap.png" },
    "security": { "text": "Your Survey is not completed ", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    "quota": { "text": "Your Survey is not completed ", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    "terminated": { "text": "Your Survey is not completed ", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    null: { "text": "Invalid Redirection", "img": "https://ik.imagekit.io/websamp/Undraw/undraw_Cancel_re_pkdm.png" },
    
  }
  let status = statusMap[null]
 
  if (surveyStatus in statusMap) {
    status = statusMap[surveyStatus];

  }
  setTimeout(() => {
    if (secound == 0) {
      history.push("/dashboard");
    }
    setSecound(secound - 1)
  }, 1000)


  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Row>
                <Col>
                  <Title level={1}>Post Survey Page</Title>
                </Col>
                
                <Col style={{marginTop:"200px", marginLeft:"20px"}}>
                  
                  <Title level={1}>{status.text}</Title>

                  <Paragraph>This Page will redirect to your home page in {secound} secounds</Paragraph>
                 
                </Col>
              </Row>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              {/* <img src={signinbg} alt="" /> */}
              <img src={status.img} alt="" />
            </Col>
          </Row>

        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2022  <a href="#pablo">Continumm insights</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
}
export default CaptureResponse;