import './Footer.css';
import { Layout } from 'antd';
const {Footer} = Layout;

function WebFooter () {
return (
    <>

<Footer
      style={{
        textAlign: 'center',
      }}
    >
     © Opinion Bull Tool  
    </Footer>
   

  </>
  );
}

export default WebFooter;
