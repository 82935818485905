import ProfileSurvey from "../../components/profilesurvey";
import PersonalProfileModal from "../../components/profileUpdate";
import { UserCoverPage} from "../../components/profilesurvey/userCoverPage";

export const Profile = () => {

  return (
    <>
      <UserCoverPage/>
      <ProfileSurvey />
      <PersonalProfileModal/>
    </>
  );
}

