import { Table, Card, Tag, Typography } from 'antd';
import { pointTransactions } from '../../redux/actions/rewardActions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SmileOutlined,
  SyncOutlined,
} from '@ant-design/icons';


function TransactionHistory() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pointTransactions({ "filters": {}, "page": 1, "order_by":["-created_at"], }));
  }, [dispatch]);


  const { transactionData = {}, fetching } = useSelector(({ reward }) => reward);
  console.log("transactions-history:", transactionData);
  const { items = [], pagination = {} } = transactionData
  const { total = 20, page = 1, page_size = 10 } = pagination
  let tableParams = { pagination: { current: page, pageSize: page_size, total: total } }

  const handleTablePageChange = (pagination, filters, sorter) => {
    tableParams.pagination.current = pagination.current
    dispatch(pointTransactions({ "filters": {}, "page": pagination.current, "order_by":["-created_at"], }));
  };

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data, record) => (
        <>
          <Typography.Title level={5}>
            {record.amount}  {record.currency}
          </Typography.Title>
          <Typography.Text>{record.description}</Typography.Text>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, record) => (
        <>

          {["SUCCESS"].includes(record.status) &&
            <Tag icon={<CheckCircleOutlined />} color="#285430">
              SUCCESS
            </Tag>
          }
          {["APPROVED"].includes(record.status) &&
            <Tag icon={<CheckCircleOutlined />} color="#A4BE7B">
              {record.status}
            </Tag>
          }
          {["FAILED", "APPROVAL_REJECTED"].includes(record.status) &&
            <Tag icon={<CloseCircleOutlined />} color="error">
              {record.status}
            </Tag>
          }
          {["PENDING_APPROVAL"].includes(record.status) &&
            <Tag icon={<SmileOutlined />} color="lime">
              {record.status}
            </Tag>
          }
          {["INITIATED"].includes(record.status) &&
            <Tag icon={<SmileOutlined />} color="#5F8D4E">
              {record.status}
            </Tag>
          }
          {["PENDING"].includes(record.status) &&
            <Tag icon={<SyncOutlined spin />} color="warning">
              PENDING
            </Tag>
          }
          {["OTHER"].includes(record.status) &&
            <Tag icon={<ClockCircleOutlined />} color="lime">
              MANAUL
            </Tag>
          }
        </>
      ),
    },
    {
      title: "Date&Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, record) => (
        <>
          <div>{record.created_at}</div>
        </>
      ),
    },


  ];


  return (
    <>
      <Card title="Transaction History" bordered={false} className="criclebox  tablespace mb-24">
        <div className="table-responsiv-sm">
          <Table
            columns={columns}
            width="100"
            dataSource={items}
            pagination={tableParams.pagination}
            loading={fetching}
            rowKey={(record) => record.id}
            onChange={handleTablePageChange}
            className="ant-border-space"
          />
        </div>
      </Card>
    </>
  )
}
export default TransactionHistory;