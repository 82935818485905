import surveyService from "../../../services/surveyService";
import{
    USER_SURVEY_DATA_REQUESTED,
    USER_SURVEY_DATA_SUCCESS,
    USER_SURVEY_DATA_ERROR,
   

} from "./actionTypes";
import { notification } from "antd";

export const getUserSurveyList = (body) => async (dispatch, getState) => {
    dispatch({
      type: USER_SURVEY_DATA_REQUESTED,
    });
  
    try {
      const response = await surveyService.userAssignedSurvey(body);
      if (response?.data?.error) {
        dispatch({
          type: USER_SURVEY_DATA_ERROR,
          payload: response.data.message,
        });
        notification.error({message: "Unable to Fetch data"})
      } else {
        dispatch({
          type: USER_SURVEY_DATA_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_SURVEY_DATA_ERROR,
        payload: error?.response?.data?.message,
      });
      notification.error({message: "Unable to Fetch data"})
    }
  };