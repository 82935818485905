import { Modal } from "antd";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  .content-padding {
    padding: 32px 16px;
  }
`;

export const LoginInformationContent = styled.div`
  background-image: url("https://assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/build/client/images/patternImg.0edf5760.svg") !important;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-left-radius: 12px;

`;

export const GoogleLoginButtonContainer = styled.div`
  padding-top: 16px;
  text-align: center;

`;
