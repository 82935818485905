

import { Button, Card, Form, Input, Select, Space, Typography } from 'antd';
import React from 'react';
import { withdrawRequestUser } from '../../redux/actions/rewardActions';

import { useDispatch } from 'react-redux';
const { Title, Paragraph } = Typography;

function Withdraw() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (data) => {
    const withdrawDetails = { amount: data.amount, method: data.method, account_details: { number: data.number } }
    console.log('withdrawDetails:', withdrawDetails);
    dispatch(withdrawRequestUser(withdrawDetails));
  };


  return (
    <>

      <Card
        title={<><Title level={3}>Withdraw your points</Title></>}
        className="criclebox tablespace mb-24"
        bordered="false">
        <div style={{ margin: "20px" }}>
          <p style={{fontSize: '13px', fontWeight: 'bold' }}>Minimum Amount to be withdrawn is 1000</p>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="amount"
              label="Amount"
              key="amount"
              width="50px"
            >

              <Input type="number" />

            </Form.Item>
            <Form.Item
              name="method"
              label="Method"
              display="flex"
              key="method"
            >

              <Select name="method" getPopupContainer={trigger => trigger.parentElement}>
                <Select.Option getPopupContainer={trigger => trigger.parentElement} value="UPI">UPI</Select.Option>
              </Select>

            </Form.Item>
            <Form.Item
              name="number"
              label="Phone Number"
              display="flex"
              key="account_details.number"
              rules={[
                {
                  len: 10,
                  message: "Phone number must be 10 digit number"
                }
              ]}
            >
              <Input type="number" />

            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" >
                  Withdraw
                </Button>

              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  )

}
export default Withdraw;