import Transaction from "../../components/Transactions";
import ProfileModal from "../../components/profileUpdate";
import { UserCoverPage} from "../../components/profilesurvey/userCoverPage";

export const Points = () => {
  return (
    <>
      <UserCoverPage/>
      <Transaction/>
      <ProfileModal/>
    </>
  );
}

