import {
  ALL_CATEGORY_DATA_REQUESTED,
  ALL_CATEGORY_DATA_SUCCESS,
  ALL_CATEGORY_DATA_ERROR,
  CATEGORY_DATA_SUBMIT_INITIATED,
  CATEGORY_DATA_SUBMIT_SUCCESS,
  CATEGORY_DATA_SUBMIT_ERROR,
} from "./actionTypes";

import ProfileService from "../../../services/profileService";
import { notification } from "antd";

export const profileSurveyCategory = () => async (dispatch, getState) => {
  dispatch({
    type: ALL_CATEGORY_DATA_REQUESTED,
  });

  try {
    const response = await ProfileService.getAllCategoryData();
    if (response?.data?.error) {
      dispatch({
        type: ALL_CATEGORY_DATA_ERROR,
        payload: response.data.message,
      });
    } else {
      dispatch({
        type: ALL_CATEGORY_DATA_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    notification.error({
      message: error?.response?.data?.message,
    });
    dispatch({
      type: ALL_CATEGORY_DATA_ERROR,
      payload: error?.response?.data?.message,
    });
  }
};

export const submitCategoryData =
  (categoryData, onSubmitSuccess) => async (dispatch, getState) => {
    dispatch({
      type: CATEGORY_DATA_SUBMIT_INITIATED,
    });

    try {
      const response = await ProfileService.saveCategoryData(categoryData);
      if (response?.data?.error) {
        dispatch({
          type: CATEGORY_DATA_SUBMIT_ERROR,
          payload: response.data.message,
        });
      } else {
        notification.success({
          message: "Answer Submitted Successfully",
        });
        onSubmitSuccess();
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message,
      });
      dispatch({
        type: CATEGORY_DATA_SUBMIT_ERROR,
        payload: error?.response?.data?.message,
      });
    }
  };
