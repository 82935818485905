import {
  TRANSACTION_DATA_REQUESTED,
  TRANSACTION_DATA_SUCCESS,
  TRANSACTION_DATA_ERROR,
  REFER_CODE_SUBMIT_INITIATED,
  REFER_CODE_SUBMIT_SUCCESS,
  REFER_CODE_SUBMIT_ERROR,
  INVITE_FRIEND_INITIATED,
  INVITE_FRIEND_SUCCESS,
  INVITE_FRIEND_ERROR,
  LIST_REFFERALS_REQUESTED,
  LIST_REFFERALS_SUCCESS,
  LIST_REFFERALS_ERROR,
  LIST_REFFERALS_TRANSACTION_REQUESTED,
  LIST_REFFERALS_TRANSACTION_SUCCESS,
  LIST_REFFERALS_TRANSACTION_ERROR,
  WITHDRAW_REQUEST_INITIATED,
  WITHDRAW_REQUEST_SUCCESS,
  WITHDRAW_REQUEST_ERROR,

} from "../actions/rewardActions/actionTypes";

const INITIAL_STATE = {
  transactionData: [],
  myRefferalData: {},
  listReferralsData: {},
  listReferralsTransactionData: {},
  myWithdrawData: [],
  fetchingRefferalData: false,
  fetchingRefferalTransactionData: false,
  refferalDataError: null,
  refferalTransactionDataError: null,
  fetchingTransactionData: false,
  transactionDataError: null,
  submitingCode: false,
  submitingCodeError: null,
  submitingwithdrawDetails: false,
  submitingwithdrawDetailsError: null,
  invite: false,
  inviteError: null,
};

const rewardReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TRANSACTION_DATA_REQUESTED:
      return {
        ...state,
        fetchingTransactionData: true,
        transactionDataError: null,
      };
    case TRANSACTION_DATA_SUCCESS:
      return {
        ...state,
        transactionData: payload,
        fetchingTransactionData: false,
      };
    case TRANSACTION_DATA_ERROR:
      return {
        ...state,
        fetchingTransactionData: false,
        transactionDataError: payload,
      };
    case LIST_REFFERALS_REQUESTED:
      return {
        ...state,
        fetchingRefferalData: true,
        refferalDataError: null,
      };
    case LIST_REFFERALS_SUCCESS:
      return {
        ...state,
        listReferralsData: payload,
        fetchingRefferalData: false,
      };
    case LIST_REFFERALS_ERROR:
      return {
        ...state,
        fetchingRefferalData: false,
        refferalDataError: payload,
      };
    case LIST_REFFERALS_TRANSACTION_REQUESTED:
      return {
        ...state,
        fetchingRefferalTransactionData: true,
        refferalTransactionDataError: null,
      };
    case LIST_REFFERALS_TRANSACTION_SUCCESS:
      return {
        ...state,
        listReferralsTransactionData: payload,
        fetchingRefferalTransactionData: false,
      };
    case LIST_REFFERALS_TRANSACTION_ERROR:
      return {
        ...state,
        fetchingRefferalTransactionData: false,
        refferalTransactionDataError: payload,
      };
    case REFER_CODE_SUBMIT_INITIATED:
      return {
        ...state,
        submitingCode: true,
        submitingCodeError: null,
      };
    case REFER_CODE_SUBMIT_SUCCESS:
      return {
        ...state,
        submitingCode: false,
      };
    case REFER_CODE_SUBMIT_ERROR:
      return {
        ...state,
        submitingCode: false,
        submitingCodeError: payload,
      };

    case WITHDRAW_REQUEST_INITIATED:
      return {
        ...state,
        submitingwithdrawDetails: true,
        submitingwithdrawDetailsError: null,
      };
    case WITHDRAW_REQUEST_SUCCESS:

      return {
        ...state,
        payload: payload,
        submitingwithdrawDetails: false,
      };
    case WITHDRAW_REQUEST_ERROR:
      return {
        ...state,
        submitingwithdrawDetails: false,
        submitingwithdrawDetailsError: payload,
      };
    case INVITE_FRIEND_INITIATED:
      return {
        ...state,
        invite: true,
        inviteError: null,
      };
    case INVITE_FRIEND_SUCCESS:

      return {
        ...state,
        payload: payload,
        invite: false,
      };
    case INVITE_FRIEND_ERROR:
      return {
        ...state,
        invite: false,
        submitingCodeError: payload,
      };
    default:
      return state;
  }
};

export default rewardReducer;