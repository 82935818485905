export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FETCHING_ME_SUCCESS = "FETCHING_ME_SUCCESS";
export const FETCHING_ME_FAILED = "FETCHING_ME_FAILED";

export const UPDATE_ME_INITIATED="UPDATE_ME_INITIATED";
export const UPDATE_ME_SUCCESS = "UPDATE_ME_SUCCESS";
export const UPDATE_ME_FAILED = "UPDATE_ME_FAILED";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
