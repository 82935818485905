import React from "react";
import aboutTopbg from "../assets/img/referal-banner.jpg";
import referral from "../assets/img/refer.jpg";

// Assets

export default function Referral() {
  return (
    <>
      <section>
        <div className="about-home">
          <div className="img">
            <img src={aboutTopbg} alt="" />
          </div>
          <div className="wrapper">
            <div className="text text-center ">
              <h2 classNameName="whiteColor">Referral</h2>
              <p className="whiteColor">All your need to know about Referral</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-30 mb-30">
        <div
          className="row mt-sm-5  about"
        >
          <div className="col-sm-6 image-box">
            <img src={referral} alt="" />
          </div>

          <div className="col-sm-6 pr-5 details">
            <div className="value-two__right-content">
              <div className="section-title text-left">
                <h2 className="whiteColor">REFERRAL PROGRAM</h2>
              </div>
              <h3 className="whiteColor">How It Works?</h3>
              <p className="value-two__text">
                Refer Websamp to a friend using your referral code in the
                Dashboard. If they sign up using your code, you get an instant
                reward. Additionally, for all the rewards they opt for, you get
                the same. It’s a win-win for everyone.
              </p>
              <button className="btn btn-bg3">Read More</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
