import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notification, Form, Checkbox } from "antd";
import { Col, Row, Input, Divider } from "antd";
import { socialLogin } from "../../../../redux/actions/authActions/auth";
import { GoogleLogin } from "@react-oauth/google";

import { continuumEmailRegister } from "../../../../services/identityService";
import Signup from "./SignUp";

const LoginModal = () => {

 
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const onGoogleLoginSuccess = (res) => {
    setIsLoginInProgress(true)
    console.log("success:", res);
    dispatch(socialLogin("google", res))
    .then(() => {
      setIsLoginInProgress(false)
      history.push("/profile");
    })
    .catch(() => {
      setIsLoginInProgress(false)
      notification.error({message: "Login/Registration Failed"})
      history.push("/");
    });
  };
  const { message } = useSelector((state) => state.message);

  const onGoogleLoginFailure = () => {
    notification.error({ message: "Goolge Login Failed" });
  };

  const showLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    continuumEmailRegister(values.name, values.email, values.password);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const[SignUpPage,setSignUpPage] =useState(false)

  const ToggleClass = () => {
    setSignUpPage(!SignUpPage); 
   };

  return (
    <>
      <div className="login-modal-wrapper">
        <Row>
          <Col span={24} className="content-padding">
            <div className=" auto-button-group">
              <span className="mx-center">
                <GoogleLogin
                  onSuccess={onGoogleLoginSuccess}
                  onError={onGoogleLoginFailure}
                  text="continue_with"
                  useOneTap
                />
              </span>
            </div>

          
            {/* <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "The input is not valid E-mail!" },
                ]}
              >
                <label htmlFor="email">Email Address</label>
                <Input placeholder="email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <label htmlFor="password">Password</label>
                <Input placeholder="Password" />
              </Form.Item>

              <div className="flexSpaceCenter check mt-25">
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Should accept agreement")
                            ),
                    },
                  ]}
                >
                  <Checkbox>I agree</Checkbox>
                </Form.Item>{" "}
                <span>Forget Password</span>{" "}
              </div>

              <Form.Item>
                <div className="button-group mt-30">
                  <button
                    className="btn btn-bg3"
                    type="primary"
                    htmlType="submit"
                  >
                    Login
                  </button>
                  <span className=" btn btn-bg4" onClick={showLoginModal}>Sign Up</span>
                
                </div>
              </Form.Item>
            </Form> */}
            <p className="mt-30 term whiteColor">
              By signing up, you agree to Opinion Bull <br />{" "}
              <NavLink to="term-service">Terms </NavLink>&{" "}
              <NavLink to="privacy-policy">Privacy Policy </NavLink>
            </p>
          </Col>
        </Row>
      </div>
      {/* <div onClick={ToggleClass} className={SignUpPage ? "show-signup signup" : "signup"}>

      
        <Signup/>
      </div> */}
      
      {isLoginModalOpen && (
        <Signup
          isLoginModalOpen={isLoginModalOpen}
          setIsLoginModalOpen={setIsLoginModalOpen}
        />
      )}
    </>
  );
};

export default LoginModal;
