
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    FETCHING_ME_SUCCESS,
    FETCHING_ME_FAILED,
    UPDATE_ME_INITIATED,
    UPDATE_ME_FAILED,
    UPDATE_ME_SUCCESS
  } from "./types";
  
  import {
    continuumEmailLogin,
    continuumEmailRegister,
    continuumLogin,
    continuumLogout,
    continuumMe,
    continuumMeUpdate,
  } from "../../../services/identityService";
  
  export const register = (username, email, password) => (dispatch) => {
    return continuumEmailRegister(username, email, password).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: REGISTER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
  
  export const login = (username, password) => (dispatch) => {
    return continuumEmailLogin(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  

  export const socialLogin = (provider, data) => (dispatch) => {
    return continuumLogin(provider, data).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const logout = () => (dispatch) => {
    continuumLogout().then(()=>{
      dispatch({
        type: LOGOUT,
      });
    });
  
    
  };
  
  export const me = () => (dispatch) => {
    return continuumMe().then((response) => {
      dispatch({
        type: FETCHING_ME_SUCCESS,
        payload: { user: response.data },
      });
    });
  
  };
  
  export const me_update = (updatedMe) => (dispatch) => {
    return continuumMeUpdate (updatedMe).then((response) => {
      dispatch({
        type: UPDATE_ME_SUCCESS,
        payload:{ user: response.data },
      });
    });
  
  };