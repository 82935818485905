import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/flexboxgrid.min.css";
import "../style/index.css";


// Sections


import { getIpDetails } from "../../services/identityService";

import TermService from "./psgeComponents/TermService";
import TopNavbar from "./psgeComponents/header/Header";

import Footer from "./psgeComponents/footer";


export default function TermServicePage() {
  getIpDetails().then((res)=>{})
  const history = useHistory();

  const { isLoggedIn } = useSelector((state) => state.auth);
  if (isLoggedIn) {
    history.push("/dashboard");
  }

  return (
    <>
      <TopNavbar />
      <div className="policy-container" style={{marginTop:"110px", marginLeft:"50px", marginRight:"50px"}}>
      <TermService></TermService>
      </div>
      
      <Footer />
    </>
  );
}