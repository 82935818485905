import { Row, Col, Card, Table, Avatar, Typography, Button, Tag } from "antd";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getUserSurveyList } from "../redux/actions/surveyActions/index"

import Statistics from "../components/statistics";
import TransactionHistory from "../components/Transactions/transactionhistory";


const { Title } = Typography;

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSurveyList({"order_by":["-created_at"],}));
  }, [dispatch]);

  const { surveys = {}, fetching = false } = useSelector(({ assignedSurveys }) => assignedSurveys);
  const { items = [], pagination = {} } = surveys
  const { total = 20, page = 1, page_size = 10 } = pagination
  let tableParams = { pagination: { current: page, pageSize: page_size, total: total } }
  console.log("Surveys:", surveys);

  let ipDetail = localStorage.getItem("ip_details")
  if (ipDetail === undefined || ipDetail === null) {
    ipDetail = '{}'
  }
  ipDetail = JSON.parse(ipDetail)
  const handleTablePageChange = (pagination, filters, sorter) => {
    tableParams.pagination.current = pagination.current
    dispatch(getUserSurveyList({ "filters": {}, "page": tableParams.pagination.current, "order_by":["-created_at"], }));
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const colorMap = {
    "NEW": "lime",
    "LIVE": "green",
    "PAUSE": "gold",
    "CLOSED": "red",
    "INVOICE": "blue",
    "PAID": "purple"
  }

  const columns = [

    {
      title: <Title level={5}> My Survey</Title>,
      dataIndex: "ui_title",
      key: "ui_title",
      render: (data, record) => (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="round"
              size={30}
              src={record.survey.ui_icon}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>{record.survey.ui_title} </Title>

              <p>{record.survey.ui_description}</p>
            </div>
          </Avatar.Group>

        </>
      ),
    },

    {
      title: <Title level={5}>Survey Status</Title>,
      dataIndex: "status",
      key: "status",
      render: (data, record) => (
        <>
          <div>

            <Tag color={colorMap[record.survey.status]}><Title level={5}>{record.survey.status}</Title></Tag>

          </div>


        </>
      ),
    },
    {
      title: <Title level={5}>My Status</Title>,
      dataIndex: "response_status",
      key: "response_status",
      render: (data, record) => (
        <>
          <div>{record.response_status}</div>
        </>
      ),
    },
    {
      title: <Title level={5}>Earning</Title>,
      dataIndex: "respondent_cpi",
      key: "respondent_cpi",
      render(data, record) {
        return {
          props: {
            style: { text: "green" }
          },
          children: <div>{record.survey.respondent_cpi} {record.survey.respondent_currency}</div>
        };
      }
    },

    {
      title: <Title level={5}>Start Date : End Date</Title>,
      dataIndex: "start_date",
      key: "date_time",
      render: (data, record) => (
        <>
          <Tag color="green">{record.survey.start_date}</Tag>to <Tag color="orange">{record.survey.end_date}</Tag>

        </>
      )
    },

    {
      title: <Title level={5}>Action</Title>,
      dataIndex: "url",
      key: "url",
      render: (data, record) => (
        <>
          <Button onClick={() => openInNewTab(record.survey.url + "?ip=" + ipDetail.ip + "&country=" + ipDetail.country)}>Start</Button>
        </>
      ),
    },

  ];

  return (
    <>
      <Statistics/>
      <div className="site-card-wrapper" style={{ marginTop: '2%' }}>
        <Row gutter={16} >
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Surveys Assigned"
            >
              <div className="table-responsive">

                <Table
                  columns={columns}
                  dataSource={items}
                  pagination={tableParams.pagination}
                  loading={fetching}
                  rowKey={(record) => record.id}
                  onChange={handleTablePageChange}
                  className="ant-border-space"
                >

                </Table>

              </div>
            </Card>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <TransactionHistory></TransactionHistory>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;