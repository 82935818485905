
import React from "react";
import aboutTopbg from "../assets/img/aboutBg.jpg"
import aboutpbg from "../assets/img/about-content-img.jpg"

// Assets


export default function About() {

  return (
    <>

<section>
        <div className="about-home">
            <div className="img">
                <img src={aboutTopbg} alt=""/>
            </div>
            <div className="wrapper">
                <div className="text text-center ">
                    <h2 classNameName="whiteColor">
                        About Us
                    </h2>
                    <p className="whiteColor">all your need to know about us
                    </p>

                </div>
            </div>
        </div>

    </section>

    <div className="container mt-30 mb-30">
        <div className="row mt-sm-5  about" >
            <div className="col-sm-6 image-box">
            <img src={aboutpbg} alt="" />
            </div>

            <div className="col-sm-6 pr-5 details">

                <div className="value-two__right-content">
                    <div className="section-title text-left">

                        <h2 className="whiteColor">WELCOME to
                            Opinion Bull</h2>
                    </div>
                    <h3 className="whiteColor">opinionbull.com</h3>
                    <p className="value-two__text">Started in 2018, with a dream of providing the best value for our consumers and clients, Opinion Bull is driven to a change in how brands create and launch products. We champion market research and technology. When you sign up on our tool, you can be rest assured of your data security. All the survey responses are completely anonymous and no personal information is shared with our clients. You can refer to our FAQ section, for more detailed information or if you wish to have a direct response you can fill our contact form on the contact page. We’d be happy to solve any of your queries.</p>
                    <button className="btn btn-bg3">
                        Read More
                    </button>
                </div>
            </div>
        </div>


    </div>


    </>
  );
}

