import { Menu, Button } from "antd";
import {
  LogoutOutlined,
  AppstoreOutlined,
  FormOutlined,
  UserOutlined,
  BarsOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { NavLink, useLocation } from "react-router-dom";
import { useState } from 'react';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [

  getItem(<NavLink to="/dashboard"><span className="icon"><AppstoreOutlined/> </span><span className="label">Dashboard</span></NavLink>, "1"),
  getItem(<NavLink to="/profile"><span className="icon"><FormOutlined/></span> <span className="label">Profile Surveys</span></NavLink>, '2'),
  getItem(<NavLink to="/personal-details"><span className="icon"><UserOutlined /></span> <span className="label">Personal Details</span></NavLink>, '3'),
  getItem(<NavLink to="/points"><span className="icon"><BarsOutlined /></span> <span className="label">PassBook Details</span></NavLink>, '4'),
  getItem(<NavLink to="/refer"><span className="icon"><UsergroupAddOutlined /></span> <span className="label">Refer Details</span></NavLink>, '5'),
  getItem(<NavLink to="/signout"><span className="icon"><LogoutOutlined color="red" /> </span><span className="label">Sign Out</span></NavLink>, "9"),

];

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");



  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <div className="brand" >
        <img src="/logo.png" alt="" style={{ width: "auto", height: "auto" }} />
      </div>
      <div className="sidebar">
        <Menu
          mode="inline"
          theme="light"
          defaultSelectedKeys={['2']}
          defaultOpenKeys={['sub1']}
          // inlineCollapsed={collapsed}
          items={items}
        >

        </Menu>
      </div>


    </>
  );
}

export default Sidenav;
