import Details from "../../components/detailsTab/details";
import ProfileModal from "../../components/profileUpdate";
import { UserCoverPage} from "../../components/profilesurvey/userCoverPage";

export const Detail = () => {
  return (
    <>
      <UserCoverPage/>
      <Details/>
      <ProfileModal/>
    </>
  );
}

