import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import "./header.css";
import { CaretDownOutlined } from "@ant-design/icons";
// Components

import BurgerIcon from "../../assets/svg/BurgerIcon";
import CloseIcon from "../../assets/svg/CloseIcon";

import about from "../../assets/img/about.jpg";
import referral from "../../assets/img/referral.jpg";
import faq from "../../assets/img/faq.jpg";

export default function Header() {

  const[click,setClick] =useState(false)
  const openMenu = () => setClick(!false)
  const closeMenu = () => setClick (false)


  const[submenu,setsubmenu] =useState(false)
  const[submenu2,setsubmenu2] =useState(false)
  const[submenu3,setsubmenu3] =useState(false)

  const ToggleClass = () => {
    setsubmenu(!submenu); 
   };
   const ToggleClass2 = () => {
    setsubmenu2(!submenu2); 
   };
   const ToggleClass3 = () => {
    setsubmenu3(!submenu3); 
   };

 

 

  return (
    <>
      <header>
    <div className="header">
     

      
      <nav>
        <div className="logo  ">
        <NavLink className="logoicon" to="home" smooth={true}>
              <img src="/logo.png" alt="logo" className="logo-img" />
            </NavLink>
        </div>
        <div className={click ? "menu active" : "menu"}>
          <ul className="main-ul">
          <li><NavLink to="/" >Home</NavLink> <a href="contact.html"></a></li>
            <li onClick={ToggleClass}>
              <a href="javascript:void(0)">About  </a> <span  className="switch-toggle tc1"><CaretDownOutlined /></span>
              <div className={submenu ? "drop open" : "drop"}>
                <div className="img">
                  <div className="text">
                    <h2>About Us</h2>
                  </div>
                  <img src={about} alt="" />
                </div>
               <div className="about-info">
                <p>Started in 2018, with a dream of providing the best value for our consumers and clients, Opinion Bull is driven to a change in how brands create and launch products. We champion market research and technology. When you sign up on our tool, you can be rest assured of your data security. </p>
<NavLink className=" btn-drop-box .btn-bg3" to="about" >Read More</NavLink>
               </div>
              </div>
            </li>
          
            <li onClick={ToggleClass2}>
            <a href="javascript:void(0)">FAQ </a><span className="switch-toggle tc2"><CaretDownOutlined /></span>
              <div className={submenu2 ? "drop open" : "drop"}>
                <div className="img">
                  <div className="text">
                    <h2>FAQ</h2>
                  </div>
                  <img src={faq} alt="" />
                </div>
               <div className="about-info">
                <p>Opinion Bull is a great and simple way to earn rewards. Below you’ll find answers to the most frequently asked questions. We periodically update this section. If you have a question and cannot find the answer among those listed below, you can fill our contact form on the contact page.</p>
              {  // <Link
                //   activeClass="active"
                //   className=" btn-drop-box .btn-bg3"
                //   to="faq"
                //   spy={true}
                //   smooth={true}
                //    offset={-80}
                //   duration={500}
                //   onClick={closeMenu}
                // >
                //  Read More
                // </Link>
              }
                <NavLink className=" btn-drop-box .btn-bg3" to="Faq" >Read More</NavLink>
               </div>
              </div>
            </li>
            <li onClick={ToggleClass3}>
              <a href="#">Referral</a><span className="switch-toggle tc3"><CaretDownOutlined /></span>
              <div className={submenu3 ? "drop open" : "drop"}>
                <div className="img">
                  <div className="text">
                    <h2>Referral</h2>
                  </div>
                  <img src={referral}alt="" />
                </div>
               <div className="about-info">
                <p>We believe in community and we’d like nothing more than your friends and family the reap the benefits of our service just like you. Every time you refer someone to Opinion Bull, you not only get an instant reward, but also every reward they opt for, you get that too.</p>
                <NavLink className=" btn-drop-box .btn-bg3" to="referral" >Read More</NavLink>
               </div>
              </div>
            </li>
            {/* <li>
              <a href="#">Contact</a><span className="switch-toggle tc3"><CaretDownOutlined /></span>
              <div className="drop drop-1 ">
                <div className="img">
                  <div className="text">
                    <h2>Contact</h2>
                  </div>
                  <img src={wall} alt="" />
                </div>
               <div className="about-info">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsa cumque quo velit iusto similique aperiam eos, consequatur totam ullam rem dolores perferendis qui facilis culpa nobis vero ducimus numquam deserunt corrupti possimus. Quibusdam asperiores odit atque nulla. Earum laboriosam eveniet velit expedita quidem, enim necessitatibus doloribus quia, sequi ipsa doloremque quo saepe voluptatem animi totam quos nobis id aut unde aliquam? Aspernatur, cum ipsa! Nemo non officiis saepe unde? Molestias quibusdam ex debitis ipsa dolore atque dolores pariatur assumenda inventore possimus maxime similique suscipit ullam dolorum adipisci neque distinctio voluptas, recusandae ad veritatis ratione eius. Vero velit minus animi.</p>
                <button className="btn btn-drop-box .btn-bg3">Read More</button>
               </div>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="hamburger" >
              {click ? (<CloseIcon onClick={closeMenu}/>) : (<BurgerIcon onClick={openMenu}/>) }
            </div>
      </nav>
    </div>
   
  </header>
 
    
    </>
  );
}
