import React from "react";
import {
  Button,
  Space,
  Modal
} from "antd";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { EditTwoTone } from '@ant-design/icons';

import "./details.css";
import { me } from "../../redux/actions/authActions/auth";
import EditUserDrawer from "./editUserDrawer";
const { confirm } = Modal;

function showConfirm() {
  confirm({
    title: 'Confirm Delete Account',
    content:
      'Once you delete your account we will not be able to recover your account and correponding data',
    async onOk() {
      try {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        });
      } catch (e) {
        return console.log('Oops errors!');
      }
    },
    onCancel() {},
  });
}

function Details() {
  const [isPTag, setPTag] = useState(true);
  const [popConfirmOpen, setPopConfirmOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isEditableDrawerOpen, setIsEditableDrawerOpen] = useState(false);
  const [userInformation, setUserInformation] = useState({});
  const { user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const reloadUser = () => {
    dispatch(me({}));
  }

  const showPopconfirm = () => {
    setPopConfirmOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setPopConfirmOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setPopConfirmOpen(false);
  };

  const onEditClick = (userInformation) => {
    setIsEditableDrawerOpen(true);
    setUserInformation(userInformation);
  };
  let temp = user == undefined ? "Temp" : user.name;
  return (
    <>
      <div className="page-content page-container" id="page-content">
        <div className="padding">
          <div className="container">
            <div className="row card radius">
              <div className="col-md-4 user-profil-bg">
                <div className="user-profil-wrapper">
                  <div className="card-block text-center text-white">

                    <div className="user-profile">
                      <img
                        src="https://img.icons8.com/bubbles/100/000000/user.png"
                        className="img-radius"
                        alt="User-Profile-Image"
                      />
                    </div>
                    <h6 className="f-w-600">Hello! {temp}</h6>
                    <p className="word-break">{user.email}</p>
                    <Space>
                      <Button type="primary" onClick={() => onEditClick(userInformation)}>
                        Edit Profile
                      </Button>
                      <Button onClick={showConfirm} type="danger">Delete Account</Button>
                    </Space>
                    <i className=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card-block">
                  <h6 className="m-b-20 p-b-5 b-b-default f-w-600">
                    Personal Information <EditTwoTone onClick={() => onEditClick(userInformation)}/>
                  </h6>
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Email</p>
                      <h6 className="text-muted f-w-400 word-break" >{user.email}</h6>
                    </div>
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Phone</p>
                      <h6 className="text-muted f-w-400">{user.mobile ?? 'Not Available'}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Gender</p>
                      <h6 className="text-muted f-w-400" >{user.gender}</h6>
                    </div>
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">DOB</p>
                      <h6 className="text-muted f-w-400">{user.dob ?? 'Not Available'}</h6>
                    </div>
                  </div>
                  <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">
                    Account Information
                  </h6>
                  <div className="row">
                    <div className="col-sm-6">
                      {isPTag ? (
                        <p onClick={() => setPTag(false)} className="m-b-10 f-w-600"> My Referral Code</p>
                      ) : (
                        <input autoFocus onClick={() => setPTag(true)} type="text" />
                      )}


                      <h6 className="text-muted f-w-400">{user.referral_code}</h6>
                    </div>
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Current Balance </p>
                      <h6 className="text-muted f-w-400">{user.balance.INR ?? '0'} {user.currency}</h6>
                    </div>
                  </div>

                  <ul className="social-link list-unstyled m-t-40 m-b-10">
                    <li>
                      <a
                        href="#!"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="facebook"
                        data-abc="true"
                      >
                        <i
                          className="mdi mdi-facebook feather icon-facebook facebook"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="twitter"
                        data-abc="true"
                      >
                        <i
                          className="mdi mdi-twitter feather icon-twitter twitter"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="instagram"
                        data-abc="true"
                      >
                        <i
                          className="mdi mdi-instagram feather icon-instagram instagram"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
      {isEditableDrawerOpen && (
        <EditUserDrawer
          isEditableDrawerOpen={isEditableDrawerOpen}
          setIsEditableDrawerOpen={setIsEditableDrawerOpen}
          userInformation={userInformation}
          setUserInformation={setUserInformation}
          reloadUser={reloadUser}
        />
      )}
    </>
  );
}
export default Details;
