import {
    USER_SURVEY_DATA_ERROR,
    USER_SURVEY_DATA_REQUESTED,
    USER_SURVEY_DATA_SUCCESS,
   
  } from "../actions/surveyActions/actionTypes";
  
  const INITIAL_STATE = {
    surveys: {},
    fetching: false,
    error: null,
  };
  
  const surveyReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case USER_SURVEY_DATA_REQUESTED:
        return {
          ...state,
          fetching: true,
          error: null,
        };
      case USER_SURVEY_DATA_SUCCESS:
        return {
          ...state,
          surveys: payload,
          fetching: false,
        };
      case USER_SURVEY_DATA_ERROR:
        return {
          ...state,
          fetching: false,
          error: payload,
        };
      default:
          return state;
      
    }
}
    export default surveyReducer;